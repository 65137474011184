import React from 'react';
import { modularClasses } from '../../../../utils/functions';

import classes from './Spinner.module.scss';

const Spinner = props => (
  <div className={classes.wrap}>
    <div className={modularClasses([classes.spinner], props.className, classes)} />
  </div>
);

export default Spinner;
