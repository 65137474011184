import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import Backend from 'i18next-chained-backend';

const apiEndpoint = process.env.REACT_APP_API_URL.replace('/graphql', '');

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS: 'WEB_APP',
    ns: ['WEB_APP'],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it already handles this
    },
    backend: {
      backends: [
        ...(process.env.NODE_ENV === 'production' ? [LocalStorageBackend] : []), // caching is only used in production
        HttpApi,
      ],
      backendOptions: [
        // Local storage backend options - caching is only used in production
        ...(process.env.NODE_ENV === 'production' ? [{
          // 2 day cache expiration
          expirationTime: 2 * 24 * 60 * 60 * 1000,
          // Updates these values if we need to invalidate the cache with an update
          versions: {
            en: 'v1.70',
            cy: 'v1.70',
          },
        }] : []),
        // HTTP backend options
        {
          loadPath: `${apiEndpoint}/translation/{{lng}}/{{ns}}.json`,
          crossDomain: true,
          // addPath: `${apiEndpoint}/translation/test`, // Used for pushing missing
        },
      ],
    },
  });

export default i18n;
