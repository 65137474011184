import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/en-gb';
import 'moment/locale/cy';
import './i18n';

import Spinner from './components/UI/Loaders/Spinner/Spinner';

import classes from './index.module.scss';

const isIE = /* @cc_on!@ */false || !!(document as any).documentMode;
const isEdge = !isIE && !!(window as any).StyleMedia;
const App = React.lazy(() => import('./App'));

class PreLoader extends Component {
  loading = true;

  node = null

  releaseLoading = () => {
    if (!this.loading) return;
    this.loading = false;

    // Remove class without re render
    window.setTimeout(() => {
      this.node.classList.remove(classes.loading);
    }, 0);
  }

  render() {
    return (
      <div
        ref={n => this.node = n}
        className={`${classes.wrap} ${classes.loading} ${isIE || isEdge ? 'isIE' : ''}`}
      >
        <Spinner />
        <Suspense fallback="">
          <App releaseLoading={this.releaseLoading} />
        </Suspense>
      </div>
    );
  }
}

ReactDOM.render(<PreLoader />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
