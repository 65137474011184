import moment from 'moment';
import { UseTranslationResponse } from 'react-i18next';

import { PERMISSION_NAME } from '../__generated__/globalTypes';
import { getMemberEvents_getMemberEvents_events as MemberEvent } from '../hoc/API/query/tenant/getMemberEvents/__generated__/getMemberEvents';
import { getTenantUsers_getTenantUsers_tenantUsers_addresses as Address } from '../hoc/API/query/tenant/getTenantUsers/__generated__/getTenantUsers';
import { TenantContextInterface } from '../hoc/Tenant/Tenant';
/**
 * Trys to parse a string of modular scss classes, useful for passing down className to a component
 * Will add class if not found in modular object
 * @param {array} defaults - default classes
 * @param {string} str - string of classes that need parsing through modular
 * @param {object} classes - modular scss object
 */
export const modularClasses = (defaults, str, classes) => (
  [...defaults, ...(str ? str.split(' ').map(c => classes[c] || c) : [])].join(' ')
);

export const getGQLErrorMessage = (Err) => {
  if (!Err.message.includes('GraphQL error:')) return null;
  return Err.message.replace('GraphQL error: ', '');
};

/**
 * Parses enum string to readable format - eg PREFER_NOT_TO_SAY becomes Prefer Not To Say
 * @param {string} e - enum string
 */
export const parseEnum = e => e.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase());

export const parseEnumToTranslation = (e, name, t: UseTranslationResponse['t']) => {
  const translationParse = t(`enum.${name.toLowerCase().replace(/_/g, '')}.${e}`);
  return translationParse !== `enum.${name.toLowerCase().replace(/_/g, '')}.${e}` ? translationParse : parseEnum(e);
};

export const formatMoney = e => `£${e.toFixed(2)}`;

/**
 * Parses camel cased string to uppercase first chars
 * @param e - string
 */
export const parseCamelCase = (e: string) => {
  const str = e.replace(/([A-Z]+)/g, ' $1').toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Created react select options from enum
 * @param {*} e - string enum
 */
export const getOptionsFromEnum = e => Object.keys(e).map(k => ({
  value: k,
  label: parseEnum(e[k]),
}));

/**
 * Returns react select options and tries to translate labels
 * @param e - string enum
 * @param t - i18next translation functions
 */
export const getTranslatedOptionsFromEnum = (e, name: string, t: UseTranslationResponse['t']) => Object.keys(e).map((k) => {
  const translationPrefix = `enum.${name.toLowerCase().replace(/_/g, '')}`;
  const translation = t(`${translationPrefix}.${k}`);
  if (translation.startsWith(translationPrefix)) {
    return {
      value: k,
      label: parseEnum(e[k]),
    };
  }

  return {
    value: k,
    label: translation,
  };
});

/**
 * Checks if element is in view with buffer - stores latest rect in elem object for later use
 * @param  {object}  elem
 * @return {boolean}
 */
export const inView = (elem) => {
  // eslint-disable-next-line no-param-reassign
  elem.rect = elem.getBoundingClientRect();

  const { innerHeight } = window;
  return (
    elem.rect.top < innerHeight
    && elem.rect.bottom > 0
  );
};

/**
 * Formats date and time to standard formats to be used across application
 * @param  {string}  string - date time string to convert
 * @param  {string}  format - date|time|verboseDate|datetime - defaults to datetime if left blank
 * @return {string} - returns formatted string
 */
export const formatDateTime = (string: Date | string, format = 'DD-MM-YY h:mma') => {
  if (!string) return null;

  switch (format.toLowerCase()) {
    case 'date':
      return moment(string).format('DD-MM-YY');

    case 'time':
      return moment(string).format('h:mma');

    case 'verbosedate':
      return moment(string).format('Do MMMM YYYY');

    case 'reports':
      return moment(string, ['MMM Do YYYY']).format('DD/MM/YYYY');

    case 'datetime':
      return moment(string).format('DD/MM/YYYY h:mma');

    default:
      return moment(string).format(format);
  }
};

export const formatAddress = (addr: Address): string => {
  // get all non-null parts of the address in order
  const parts = [addr.line_1, addr.line_2, addr.town, addr.county, addr.postcode, addr.country].filter(x => !!x);
  return `${addr.label}: ${parts.join(', ')}`;
};

/**
 * Automatically format a word into plural or singular against a count
 */
export const formatPlural = (word: string, count: number):string => {
  // Singular
  if (count === 1) return word;

  // Plural
  // Ends with s
  if (word.match(/s$/)) return `${word}'`;

  // Ends with y
  if (word.match(/[^aeiou]y$/)) return word.replace(/y$/, 'ies');

  // Default
  return `${word}s`;
};

export const capitalizeWord = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeWords = (str: string) => {
  const parts = str.toLowerCase().split(' ');
  const capitalParts = parts.map(p => capitalizeWord(p));
  return capitalParts.join(' ');
};

interface TimespanDescription {
  description: string;
  difference: number;
}

/**
 * Renders a timespan as a simplied description with difference in milliseconds
 * E.g. 35000ms difference = "35 seconds"
 *
 * @param start Date
 * @param end Date
 * @return TimespanDescription
 */
export const timespanDescription = (start: Date, end: Date):TimespanDescription => {
  const difference = end.getTime() - start.getTime();

  // Get flat absolute difference for length calculations even in the past
  const differenceAbs = Math.abs(difference);

  let description;

  if (differenceAbs < 60000) {
    // Seconds
    const unit = Math.floor(differenceAbs / 1000);
    description = `${unit} ${formatPlural('second', unit)}`;
  } else if (differenceAbs < 3600000) {
    // Minutes
    const unit = Math.floor(differenceAbs / 60000);
    description = `${unit} ${formatPlural('minute', unit)}`;
  } else if (differenceAbs < 86400000) {
    // Hours
    const unit = Math.floor(differenceAbs / 3600000);
    description = `${unit} ${formatPlural('hour', unit)}`;
  } else if (differenceAbs < 604800000) {
    // Days
    const unit = Math.floor(differenceAbs / 86400000);
    description = `${unit} ${formatPlural('day', unit)}`;
  } else if (differenceAbs < 2630880000) {
    // Weeks
    const unit = Math.floor(differenceAbs / 604800000);
    description = `${unit} ${formatPlural('week', unit)}`;
  } else if (differenceAbs < 31570560000) {
    // Months
    const unit = Math.floor(differenceAbs / 2630880000);
    description = `${unit} ${formatPlural('month', unit)}`;
  } else {
    // Years
    const unit = Math.floor(differenceAbs / 31570560000);
    description = `${unit} ${formatPlural('year', unit)}`;
  }

  return {
    difference,
    description,
  };
};


/**
 * Formats a timespan description from the current time with past and future tense
 * E.g. 35000ms difference = "in 35 seconds"
 *      -35000ms difference = "35 seconds ago"
 *
 * @param date Date
 * @return string
 */
export const timeSince = (date: Date):string => {
  const { difference, description } = timespanDescription(date, new Date());

  // Future span
  if (difference < 0) return `in ${description}`;

  // Past span
  return `${description} ago`;
};

/**
 * Checks different possibilities for users name and ensures
 * something is always displayed for a users name
 * @param {object} object - object to generate a users name from
 * @return {string} -the users name, email or 'unknown user'
 */
/* eslint-disable consistent-return */
export const getUserName = (object: any) => {
  if (!object) return '';
  if (object.firstName || object.lastName) {
    return `${object.firstName || ''} ${object.lastName || ''}`;
  }

  if (object.user) {
    return `${object.user.firstName || ''} ${object.user.lastName || ''}`;
  }

  if (object.tenantUser) {
    if (object.tenantUser.user) {
      return `${object.tenantUser.user.firstName} ${object.tenantUser.user.lastName || ''}`;
    }

    if (object.tenantUser.firstName || object.tenantUser.lastName) {
      return `${object.tenantUser.firstName || ''} ${object.tenantUser.lastName || ''}`;
    }

    if (object.tenantUser.email) return object.tenantUser.email;
  }

  return object.email || 'Unknown User';
};
/* eslint-enable consistent-return */

export const canPreviewFile = (mime: string) => [
  'image/jpeg',
  'image/png',
].includes(mime);

/**
 * Parses enum string to readable format - eg PREFER_NOT_TO_SAY becomes Prefer Not To Say
 * @param {string} e - enum string
 */
export const parsePermissionName = (n: PERMISSION_NAME) => {
  switch (n) {
    case 'EDIT_REGISTRATION':
      return 'Edit Registration';

    case 'VIEW_PAYMENTREQUESTGROUP':
      return 'View Invoice';

    case 'CREATE_PAYMENTREQUESTGROUP':
      return 'Create Invoice';

    case 'EDIT_PAYMENTREQUESTGROUP':
      return 'Edit Invoice';

    case 'REMOVE_PAYMENTREQUESTGROUP':
      return 'Remove Invoice';

    case 'VIEW_TENANT_USER':
      return 'View User';

    case 'CREATE_TENANT_USER':
      return 'Create User';

    case 'EDIT_TENANT_USER':
      return 'Edit User';

    case 'REMOVE_TENANT_USER':
      return 'Remove User';

    default:
      return parseEnum(n);
  }
};

export const setCookie = (name: string, value: string, days?: number, path: string = '/') => {
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=${path};`;
  } else {
    document.cookie = `${name}=${value}; path=${path};`;
  }
};

export const getCookies = (name: string, value: string) => {
  const reg = new RegExp(`^${name}=${value}$`, 'g');

  const cookies = document.cookie.split(';').reduce((acc, curr) => {
    if (curr.trim().match(reg)) {
      // eslint-disable-next-line no-shadow
      const [name, value] = curr.split('=');
      return acc.concat({ name, value });
    }
    return acc;
  }, []);

  return cookies.length > 0 ? cookies : null;
};

// Returuns a single CustomFieldGroup where groups with the same label have been merged
const mergeDuplicateGroups = (customFieldGroups: any[], label: string): any => {
  // Get duplicate Field Values
  const duplicateFieldGroups = customFieldGroups.filter(g => g.label === label);

  return {
    ...duplicateFieldGroups[0], // Use the first entry as we will be using everything but the fileds
    fields: duplicateFieldGroups.flatMap(g => g.fields),
  };
};

// Returns an array of Merged Custom Groups
// Note: this can be used almost anywhere that we need to merge fields
export const mergeCustomFieldGroups = (customFieldGroups: any[]): any[] => {
  const mergedGroups = [] as any[];
  for (const group of customFieldGroups) {
    if (!mergedGroups.find(g => g.label === group.label)) {
      mergedGroups.push(mergeDuplicateGroups(customFieldGroups, group.label));
    }
  }
  return mergedGroups;
};

export const validEventTime = (dateFrom: Date | string, dateTo?: Date | string):boolean => {
  const currentDateTime = moment();
  if (dateTo) {
    if (currentDateTime.isAfter(moment(dateTo))) return false;
    return moment(currentDateTime).isBetween(moment(dateFrom), moment(dateTo));
  }
  return moment(currentDateTime).isAfter(moment(dateFrom));
};

export const parentsGroopLiveEnabled = (parents: any[]):boolean => parents.filter(p => p.groopLiveEnabled).length > 0;

// Utility function for sorting member events into past and future
export const sortMemberEvents = (events: MemberEvent[]) => ({
  upcomingEvents: events.filter(e => !moment().isAfter(e.dateTimeTo)).sort((a, b) => moment(a.dateTimeFrom).diff(b.dateTimeFrom)) as MemberEvent[],
  pastEvents: events.filter(e => moment().isAfter(e.dateTimeTo)).sort((a, b) => moment(b.dateTimeFrom).diff(a.dateTimeFrom)) as MemberEvent[],
});

const createMoment = (val: string): moment.Moment => moment(val, ['L', moment.ISO_8601], 'en-GB');

export const dateIsValid = (val: string): boolean => createMoment(val).isValid();

export const dateIsValidOrEmpty = (val: string): boolean => (val ? dateIsValid(val) : true);

export const parseToISOString = (val: string): string => (dateIsValid(val) ? createMoment(val).toISOString() : null);

/**
 * capitalise the first letter of every word in a scentence.
 * @param scentence
 */
export const convertWordsToUpperCase = (scentence: string) => scentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

/**
 * Convert enum values to readable user value
 * @param value
 */
export const convertPaymentEnum = (value: String) => convertWordsToUpperCase(value.replace('_', ' ').toLowerCase());

export const hasPermission = (tenantContext: TenantContextInterface, permission: PERMISSION_NAME) => {
  if (!tenantContext.tenantAuthPayload.tenantUser.permissionsList) return false;
  return tenantContext.tenantAuthPayload.tenantUser.permissionsList.includes(permission);
};
